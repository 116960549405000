import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import DatePicker, { registerLocale } from 'react-datepicker';
import { parse, isValid, format } from 'date-fns';
import { sv, da } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCountries from 'ReduxHooks/useCountries';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

registerLocale('se', sv);
registerLocale('dk', da);

const LavendlaDatePicker = ({ date, onChange, id, className, placeholder, maxDate, required, countryCode, showYearDropdown }) => {
  const datepickerId = id || uniqueId('datepicker_');
  const { loggedInUser: { country: userCountry } } = useLoggedInUser();
  const { allowedCountries } = useCountries();
  const cc = countryCode || userCountry?.code ? userCountry.code : null;
  const allowedCountryCode = cc && allowedCountries.includes(countryCode) ? countryCode : 'se';
  const [selectedDate, setSelectedDate] = useState(isValid(parse(date, 'yyyy-MM-dd', new Date())) ? parse(date, 'yyyy-MM-dd', new Date()) : null);

  const handleChange = (date) => {
    setSelectedDate(date);

    if (typeof onChange === 'function') {
      const returnDate = date ? format(date, 'yyyy-MM-dd') : null;
      onChange(returnDate);
    }
  };

  return (
    <>
      <DatePicker
        id={datepickerId}
        selected={selectedDate !== 'Invalid Date' ? selectedDate : ''}
        onChange={handleChange}
        placeholderText={placeholder || null}
        minDate={showYearDropdown ? null : new Date('2017-04-01')}
        maxDate={showYearDropdown ? null : maxDate || null}
        isClearable
        dateFormat='P'
        locale={allowedCountryCode}
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown={showYearDropdown}
      />

      {required && !selectedDate
        ? (
          <span className='input__icon-required'>
            <FontAwesomeIcon
              icon={['fal', 'exclamation-triangle']}
              color='#ea8b00'
              size='lg'
            />
          </span>
          )
        : null}
    </>
  );
};

LavendlaDatePicker.propTypes = {
  date: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  required: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool
};

export default LavendlaDatePicker;
