import useSingleOrder from '../Redux/hooks/useSingleOrder';
import { getRequiredFields } from 'Library/Orders';
import { caseTypeIsEstateOrInheritance } from '../values/caseTypeDefinitions';

function useOrderTasks () {
  const { isDirty, order } = useSingleOrder();

  function getTasksForTab (tab = 'customer') {
    const { tasks = [] } = order;
    let filteredTasks = tasks.filter(({ order_tab: orderTab }) => orderTab === tab);

    if (hasAllReviewTasks(filteredTasks)) {
      filteredTasks = filteredTasks.filter((task) => filterReviewTask(task, order));
    }

    return filteredTasks;
  }

  function hasAllReviewTasks (tasks) {
    return tasks.filter(({
      send_google_review_email: sendGoogleReviewEmail,
      send_trustpilot_email: sendTrustpilotEmail,
      send_lavendla_review_email: sendLavendlaReviewEmail
    }) => sendGoogleReviewEmail || sendTrustpilotEmail || sendLavendlaReviewEmail).length === 3;
  }

  function filterReviewTask (task, order) {
    if (!task.send_google_review_email && !task.send_trustpilot_email && !task.send_lavendla_review_email) {
      return true;
    }

    const customerEmail = order?.customer?.email;
    const hasGmail = customerEmail && new RegExp(/^[a-z0-9]((\.|\+|-|_)?[a-z0-9]){5,}@g(oogle)?mail\.com$/).test(customerEmail); // eslint-disable-line prefer-regex-literals
    const hasGoogleReviewUrl = !!order?.google_review_url;
    const showGoogleReviewTask = hasGoogleReviewUrl && hasGmail;

    if (task.send_google_review_email) {
      return showGoogleReviewTask;
    }

    if (task.send_trustpilot_email) {
      return !showGoogleReviewTask;
    }

    return true;
  }

  function isMissingRequiredField (obj = {}, fields = []) {
    return Object.entries(obj).filter(([key, value]) => {
      return fields.includes(key) && (!value || value === '');
    }).length > 0;
  }

  function isMissingRequiredFields () {
    const { customer, deceased } = getRequiredFields(order);

    if (isMissingRequiredField(order.customer, customer)) {
      return true;
    }

    if (isDirty()) {
      return true;
    }

    if (order.class === 'App\\LawyerOrder' && !caseTypeIsEstateOrInheritance(order.case_type_definition)) {
      return false;
    }

    if (['App\\TherapyOrder', 'App\\CeremonyOrder'].includes(order.class)) {
      return false;
    }

    return isMissingRequiredField(order.deceased, deceased);
  }

  function calculatePercentageComplete () {
    if (!order) {
      return null;
    }

    const activeTasks = order.tasks.filter(({ optional }) => !optional);

    if (!activeTasks.length) {
      return null;
    }

    const numCompleteTasks = activeTasks.filter(({ pivot }) => pivot.completed).length;

    return Math.round((numCompleteTasks / activeTasks.length) * 100);
  }

  function getSortedTasksByPrio () {
    let filteredTasks = [...order.tasks];

    if (hasAllReviewTasks(filteredTasks)) {
      filteredTasks = filteredTasks.filter((task) => filterReviewTask(task, order));
    }

    const completed = filteredTasks.filter(({ pivot }) => pivot.completed);
    const inProgress = filteredTasks.filter(({ pivot }) => !pivot.completed);

    const highPrio = [];
    const normalPrio = [];

    for (const task of inProgress) {
      const priority = parseInt(task.priority || 3);

      if (priority === 1) {
        highPrio.push(task);
      } else {
        normalPrio.push(task);
      }
    }

    return {
      highPrio,
      normalPrio,
      completed
    };
  }

  function getTaskForSupplier (type = 'transportoer') {
    if (type === 'transportoer') {
      return order.tasks.find(({ id }) => id === 18);
    }

    return null;
  }

  return {
    calculatePercentageComplete,
    getTasksForTab,
    isMissingRequiredFields,
    getSortedTasksByPrio,
    getTaskForSupplier
  };
}

export default useOrderTasks;
