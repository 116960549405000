import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import store from './Redux/store';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ToastContainer, Flip } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'url-search-params-polyfill';
import './i18n';
import AppMain from './AppMain';

// Styles.
import '../sass/style.scss';
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#main');

// Store the original fetch function
const originalFetch = window.fetch;

// Override the fetch function to handle analytics service errors
window.fetch = function(resource, options) {
  if (typeof resource === 'string' && (
      resource.includes('ingesteer.services-prod.nsvcs.net') ||
      resource.includes('clarity.ms/collect') ||
      resource.includes('google-analytics.com')
    )) {
    return originalFetch(resource, options)
      .catch(error => {
        console.warn(`Analytics request to ${resource} failed:`, error);
        return new Response(JSON.stringify({}), {
          status: 200,
          headers: { 'Content-Type': 'application/json' }
        });
      });
  }
  return originalFetch.apply(this, arguments);
};

const LavendlaAdmin = () => {
  return (
    <GoogleOAuthProvider clientId='129973817208-q41os024ag24gushtbvth978qtvvn834.apps.googleusercontent.com'>
      <Provider store={store}>
        <ToastContainer transition={Flip} autoClose={7500} hideProgressBar />
        <BrowserRouter>
          <AppMain />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  );
};

/**
 * @todo remove this!
 * @param array
 * @return {boolean}
 */
// eslint-disable-next-line no-extend-native
Array.prototype.compare = function (array) {
  if (!array) {
    return false;
  }

  if (this.length !== array.length) {
    return false;
  }

  for (let i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].compare(array[i])) {
        return false;
      }
    } else if (this[i] !== array[i]) {
      return false;
    }
  }

  return true;
};

function sentryEnvironment () {
  if (window.location.host.includes('netlify')) {
    return 'staging';
  }

  if (window.location.host.includes('localhost')) {
    return 'local';
  }

  return 'production';
}

if (window.location.hostname !== 'localhost') {
  TagManager.initialize({
    gtmId: 'GTM-TWQKDMH'
  });
}

Sentry.init({
  dsn: 'https://cff92aa07e42492eaa1abd038d63d886@o395628.ingest.sentry.io/5477984',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.01,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.01,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.01,

  integrations: [
    new Integrations.BrowserTracing(),
    Sentry.replayIntegration(),
  ],
  environment: sentryEnvironment(),
  
  // Filter out Netlify RUM script errors
  beforeSend(event) {
    if (event.exception && event.exception.values) {
      const exceptions = event.exception.values;
      for (const exception of exceptions) {
        if (exception.stacktrace && exception.stacktrace.frames) {
          const isNetlifyRumError = exception.stacktrace.frames.some(frame => 
            (frame.filename && frame.filename.includes('/.netlify/scripts/rum')) ||
            (frame.abs_path && frame.abs_path.includes('/.netlify/scripts/rum'))
          );
          const isIngesteerError = exception.value && exception.value.includes('ingesteer.services-prod.nsvcs.net');
          if (isNetlifyRumError || isIngesteerError) {
            return null;
          }
        }
      }
    }
    return event;
  }
});

ReactDom.render(
  <LavendlaAdmin />,
  document.getElementById('main')
);

// Enable Hot Reloading
// module.hot.accept();
