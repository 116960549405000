import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactSVG from 'react-svg';
import NumNewOrders from './NumNewOrders';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useAppState from 'ReduxHooks/useAppState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdminOnly, If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function NavBar () {
  const {
    loggedInUser,
    isAdmin,
    isPaused,
    isLegalAdvisor,
    isFuneralAdvisor,
    isTombstoneAdvisor,
    isTherapist,
    isOfficiant,
    isSupplier
  } = useLoggedInUser();

  const { appState, toggleMenu } = useAppState();
  const { t } = useTranslation();

  const classes = (appState.menuState === 'open') ? 'navbar navbar-open' : 'navbar';

  const closeMenu = () => {
    toggleMenu();
  };

  function getHomePath () {
    if (isAdmin || isFuneralAdvisor) {
      return '/';
    }

    if (isLegalAdvisor) {
      return '/juridik-stats';
    }

    if (isTombstoneAdvisor) {
      return '/gravsten-stats';
    }

    if (isTherapist) {
      return '/therapy-stats';
    }

    if (isOfficiant) {
      return '/ceremony-stats';
    }

    return '/';
  }

  function getSupplierMenu () {
    return (
      <>
        <ul>
          <li>
            <span className='navbar-label'>
              {t('Sections')}
            </span>
          </li>
          <li data-item='calendar'>
            <NavLink to='/kalender' activeClassName='active' onClick={closeMenu}>
              <span className='navbar-icon'>
                <ReactSVG src='/icons/calendar.svg' />
              </span> {t('Calendar')}
            </NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <span className='navbar-label'>{t('System')}</span>
          </li>
          <li data-item='profile'>
            <NavLink to='/profil/min-profil' activeClassName='active' onClick={closeMenu}>
              <span className='navbar-icon'>
                <ReactSVG src='/icons/profile.svg' />
              </span> {t('Profile')}
            </NavLink>
          </li>
          <li data-item='log-out'>
            <NavLink to='/logout' activeClassName='active' onClick={closeMenu}>
              <span className='navbar-icon'>
                <ReactSVG src='/icons/logout.svg' />
              </span> {t('Log out')}
            </NavLink>
          </li>
        </ul>
      </>
    );
  }

  return (
    <div className={classes}>
      <div className='navigation-container'>
        {isSupplier
          ? (
              getSupplierMenu()
            )
          : (
            <>
              <ul>
                <li>
                  <span className='navbar-label'>
                    {t('Sections')}
                  </span>
                </li>
                <li data-item='home'>
                  <NavLink to={getHomePath()} exact activeClassName='active' onClick={closeMenu}>
                    <span className='navbar-icon'>
                      <ReactSVG src='/icons/home.svg' />
                    </span> {t('Home')}
                  </NavLink>
                  <ul>
                    <If condition={isAdmin || isFuneralAdvisor}>
                      <li data-item='funeral-statistics'>
                        <NavLink to='/' exact activeClassName='active' onClick={closeMenu}>
                          {t('Funeral Statistics')}
                        </NavLink>
                      </li>
                    </If>
                    <If condition={isAdmin || isLegalAdvisor}>
                      <li data-item='law-statistics'>
                        <NavLink to='/juridik-stats' exact activeClassName='active' onClick={closeMenu}>
                          {t('Law statistics')}
                        </NavLink>
                      </li>
                    </If>
                    <If condition={isAdmin || isTombstoneAdvisor}>
                      <li data-item='tombstone-statistics'>
                        <NavLink to='/gravsten-stats' exact activeClassName='active' onClick={closeMenu}>
                          {t('Tombstone statistics')}
                        </NavLink>
                      </li>
                    </If>
                    <If condition={isAdmin || isTherapist}>
                      <li data-item='therapy-statistics'>
                        <NavLink to='/therapy-stats' exact activeClassName='active' onClick={closeMenu}>
                          {t('Therapy statistics')}
                        </NavLink>
                      </li>
                    </If>
                    <If condition={isAdmin || isOfficiant}>
                      <li data-item='ceremony-statistics'>
                        <NavLink to='/ceremony-stats' exact activeClassName='active' onClick={closeMenu}>
                          {t('Ceremony statistics')}
                        </NavLink>
                      </li>
                    </If>
                  </ul>
                </li>
                <li data-item='calendar'>
                  <NavLink to='/kalender' activeClassName='active' onClick={closeMenu}>
                    <span className='navbar-icon'>
                      <ReactSVG src='/icons/calendar.svg' />
                    </span> {t('Calendar')}
                  </NavLink>
                </li>
                <If condition={isAdmin || isFuneralAdvisor}>
                  <li>
                    <NavLink to='/ordrar' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <ReactSVG src='/icons/church.svg' />
                      </span> {t('Funeral')} <NumNewOrders />
                    </NavLink>
                    <ul>
                      <If condition={!isPaused && loggedInUser.status}>
                        <li data-cy='funeral_link' data-item='funeral-new'>
                          <NavLink
                            to='/ordrar'
                            activeClassName='active'
                            onClick={closeMenu}
                            exact
                          >
                            {t('New')}
                          </NavLink>
                        </li>
                      </If>
                      <li data-item='funeral-in-progress'>
                        <NavLink
                          to='/ordrar/pagaende'
                          activeClassName='active'
                          onClick={closeMenu}
                          exact
                        >
                          {t('In progress')}
                        </NavLink>
                      </li>
                      <li data-item='funeral-archived'>
                        <NavLink
                          to='/ordrar/arkiv'
                          activeClassName='active'
                          onClick={closeMenu}
                          exact
                        >
                          {t('Archived')}
                        </NavLink>
                      </li>
                      <AdminOnly>
                        <li data-cy='funeral_link_deleted' data-item='funeral-deleted'>
                          <NavLink
                            to='/ordrar/raderade'
                            activeClassName='active'
                            onClick={closeMenu}
                            exact
                          >
                            {t('Deleted')}
                          </NavLink>
                        </li>
                      </AdminOnly>
                    </ul>
                  </li>
                </If>
                <If condition={isAdmin || isLegalAdvisor}>
                  <li>
                    <NavLink to='/juridik' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <ReactSVG src='/icons/law.svg' />
                      </span> {t('Law')} <NumNewOrders isLawyerOrder />
                    </NavLink>
                    <ul>
                      <If condition={!isPaused && loggedInUser.status}>
                        <li data-cy='legal_link' data-item='legal-new'>
                          <NavLink to='/juridik' exact activeClassName='active' onClick={closeMenu}>
                            {t('New')}
                          </NavLink>
                        </li>
                      </If>
                      <li data-item='legal-in-progress'>
                        <NavLink to='/juridik/pagaende' exact activeClassName='active' onClick={closeMenu}>
                          {t('In progress')}
                        </NavLink>
                      </li>
                      <li data-item='legal-archived'>
                        <NavLink to='/juridik/arkiv' activeClassName='active' onClick={closeMenu}>
                          {t('Archived')}
                        </NavLink>
                      </li>
                      <AdminOnly>
                        <li data-cy='legal_link_deleted' data-item='legal-deleted'>
                          <NavLink to='/juridik/raderade' activeClassName='active' onClick={closeMenu}>
                            {t('Deleted')}
                          </NavLink>
                        </li>
                      </AdminOnly>
                    </ul>
                  </li>
                </If>
                <If condition={isAdmin || isTombstoneAdvisor}>
                  <li>
                    <NavLink to='/gravstenar' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <ReactSVG src='/icons/grave.svg' />
                      </span> {t('Tombstone orders')} <NumNewOrders isTombstoneOrder />
                    </NavLink>
                    <ul>
                      <If condition={!isPaused && loggedInUser.status}>
                        <li data-cy='tombstone_link' data-item='tombstone-new'>
                          <NavLink to='/gravstenar' exact activeClassName='active' onClick={closeMenu}>
                            {t('New')}
                          </NavLink>
                        </li>
                      </If>
                      <li data-item='tombstone-in-progress'>
                        <NavLink to='/gravstenar/pagaende' exact activeClassName='active' onClick={closeMenu}>
                          {t('In progress')}
                        </NavLink>
                      </li>
                      <li data-item='tombstone-archived'>
                        <NavLink to='/gravstenar/arkiv' activeClassName='active' onClick={closeMenu}>
                          {t('Archived')}
                        </NavLink>
                      </li>
                      <AdminOnly>
                        <li data-cy='tombstone_link_deleted' data-item='tombstone-deleted'>
                          <NavLink to='/gravstenar/raderade' activeClassName='active' onClick={closeMenu}>
                            {t('Deleted')}
                          </NavLink>
                        </li>
                      </AdminOnly>
                    </ul>
                  </li>
                </If>
                <If condition={isAdmin || isTherapist}>
                  <li>
                    <NavLink to='/therapy' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <FontAwesomeIcon
                          icon={['fal', 'comments']}
                          size='lg'
                        />
                      </span> {t('Therapy orders')} <NumNewOrders isTherapyOrder />
                    </NavLink>
                    <ul>
                      <If condition={!isPaused && loggedInUser.status}>
                        <li data-cy='therapy_link' data-item='therapy-new'>
                          <NavLink to='/therapy' exact activeClassName='active' onClick={closeMenu}>
                            {t('New')}
                          </NavLink>
                        </li>
                      </If>
                      <li data-item='therapy-in-progress'>
                        <NavLink to='/therapy/pagaende' exact activeClassName='active' onClick={closeMenu}>
                          {t('In progress')}
                        </NavLink>
                      </li>
                      <li data-item='therapy-archived'>
                        <NavLink to='/therapy/arkiv' activeClassName='active' onClick={closeMenu}>
                          {t('Archived')}
                        </NavLink>
                      </li>
                      <AdminOnly>
                        <li data-cy='therapy_link_deleted' data-item='therapy-deleted'>
                          <NavLink to='/therapy/raderade' activeClassName='active' onClick={closeMenu}>
                            {t('Deleted')}
                          </NavLink>
                        </li>
                      </AdminOnly>
                    </ul>
                  </li>
                </If>
                <If condition={isAdmin || isOfficiant}>
                  <li>
                    <NavLink to='/ceremonies' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <FontAwesomeIcon
                          icon={['fal', 'gifts']}
                          size='lg'
                        />
                      </span> {t('Ceremony orders')} <NumNewOrders isCeremonyOrder />
                    </NavLink>
                    <ul>
                      <If condition={!isPaused && loggedInUser.status}>
                        <li data-cy='ceremony_link' data-item='ceremony-new'>
                          <NavLink to='/ceremonies' exact activeClassName='active' onClick={closeMenu}>
                            {t('New')}
                          </NavLink>
                        </li>
                      </If>
                      <li data-item='ceremony-in-progress'>
                        <NavLink to='/ceremonies/pagaende' exact activeClassName='active' onClick={closeMenu}>
                          {t('In progress')}
                        </NavLink>
                      </li>
                      <li data-item='ceremony-archived'>
                        <NavLink to='/ceremonies/arkiv' activeClassName='active' onClick={closeMenu}>
                          {t('Archived')}
                        </NavLink>
                      </li>
                      <AdminOnly>
                        <li data-cy='ceremony_link_deleted' data-item='ceremony-deleted'>
                          <NavLink to='/ceremonies/raderade' activeClassName='active' onClick={closeMenu}>
                            {t('Deleted')}
                          </NavLink>
                        </li>
                      </AdminOnly>
                    </ul>
                  </li>
                </If>
                <AdminOnly>
                  <li>
                    <NavLink to='/wills' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <FontAwesomeIcon
                          icon={['fal', 'file-contract']}
                          size='lg'
                        />
                      </span> {t('Wills')}
                    </NavLink>
                    <ul>
                      <li data-cy='will_link' data-item='wills-archived'>
                        <NavLink to='/wills/archive' exact activeClassName='active' onClick={closeMenu}>
                          {t('Archived')}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </AdminOnly>
                {(isAdmin || isTombstoneAdvisor || isFuneralAdvisor || isLegalAdvisor) && (
                  <li data-cy='suppliers_link'>
                    <NavLink to='/leverantorer' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <ReactSVG src='/icons/suppliers.svg' />
                      </span> {t('Suppliers')}
                    </NavLink>
                  </li>
                )}
                <If condition={isAdmin || isLegalAdvisor || isTherapist || isFuneralAdvisor || isTombstoneAdvisor || isOfficiant}>
                  <li data-item='reviews'>
                    <NavLink to='/reviews' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <FontAwesomeIcon
                          icon={['fal', 'star']}
                          size='lg'
                        />
                      </span> {t('Reviews')}
                    </NavLink>
                  </li>
                </If>
                {isAdmin
                  ? (
                    <li data-item='reports'>
                      <NavLink to='/ersattningsrapporter' activeClassName='active' onClick={closeMenu}>
                        <span className='navbar-icon reports'>
                          <ReactSVG src='/icons/reports-v-2.svg' />
                        </span> {t('Reports')}
                      </NavLink>
                    </li>
                    )
                  : (
                    !loggedInUser.has_stripe_connect && loggedInUser.country.code !== 'global' ?
                      <li data-item='reports'>
                        <NavLink to={`/ersattningsrapporter/user/${loggedInUser.id}`} activeClassName="active"
                                 onClick={closeMenu}>
                          <span className="navbar-icon reports">
                            <ReactSVG src="/icons/reports-v-2.svg"/>
                          </span> {t('Reports')}
                        </NavLink>
                      </li>
                      : null
                  )}
              </ul>
              <ul>
                <li>
                  <span className="navbar-label">{t('Manage')}</span>
                </li>
                <li data-cy="users_link" data-item='users'>
                  <NavLink to="/anvandarregister" activeClassName="active" onClick={closeMenu} data-item='users'>
                    <span className="navbar-icon">
                      <ReactSVG src='/icons/users.svg' />
                    </span> {t('User register')}
                  </NavLink>
                </li>
                <AdminOnly>
                  <li data-cy='tests_link' data-item='tests'>
                    <NavLink to='/tests' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <FontAwesomeIcon
                          icon={['fal', 'microscope']}
                          size='lg'
                        />
                      </span> {t('Tests')}
                    </NavLink>
                  </li>
                  <li data-cy='tasks_link' data-item='tasks'>
                    <NavLink to='/tasks' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <FontAwesomeIcon
                          icon={['fal', 'tasks']}
                          size='lg'
                        />
                      </span> {t('Tasks')}
                    </NavLink>
                  </li>
                  <li data-cy='settings-page' data-item='settings'>
                    {loggedInUser.countries.length > 0 && (
                      <NavLink to={`/settings/${loggedInUser.countries[0].code}`} activeClassName='active' onClick={closeMenu}>
                        <span className='navbar-icon'>
                          <FontAwesomeIcon
                            icon={['fal', 'sliders-v-square']}
                            size='lg'
                          />
                        </span> {t('Settings')}
                      </NavLink>
                    )}
                    {loggedInUser.countries.length > 1 && (
                      <ul>
                        {loggedInUser.countries.map((country) => (
                          <li key={country.id}>
                            <NavLink to={`/settings/${country.code}`} activeClassName='active' onClick={closeMenu}>
                              {country.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  <li>
                    <NavLink to='/export' activeClassName='active' onClick={closeMenu} data-item='export'>
                      <span className='navbar-icon'>
                        <ReactSVG src='/icons/export.svg' />
                      </span> {t('Export')}
                    </NavLink>
                  </li>
                  <li data-cy='competences-link' data-item='competences'>
                    <NavLink to='/kompetenser' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon font-awesome'>
                        <FontAwesomeIcon
                          icon={['fal', 'book-reader']}
                          size='lg'
                        />
                      </span> {t('Skills')}
                    </NavLink>
                  </li>
                  <li data-item='translate'>
                    <NavLink to='/translate' activeClassName='active' onClick={closeMenu}>
                      <span className='navbar-icon'>
                        <ReactSVG src='/icons/icon-translation.svg' />
                      </span> {t('Translate')}
                    </NavLink>
                  </li>
                </AdminOnly>
                <li data-item='profile'>
                  <NavLink to='/profil/min-profil' activeClassName='active' onClick={closeMenu}>
                    <span className='navbar-icon'>
                      <ReactSVG src='/icons/profile.svg' />
                    </span> {t('Profile')}
                  </NavLink>
                </li>
              </ul>
              <ul>
                <li>
                  <span className='navbar-label'>{t('System')}</span>
                </li>
                <li data-item='log-out'>
                  <NavLink to='/logout' activeClassName='active' onClick={closeMenu}>
                    <span className='navbar-icon'>
                      <ReactSVG src='/icons/logout.svg' />
                    </span> {t('Log out')}
                  </NavLink>
                </li>
              </ul>
            </>
            )}
      </div>
    </div>
  );
}

export default NavBar;
