import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { login, getLoggedInUser, sendResetEmail } from 'ReduxActions/users';
import Input from '../components/FormElements/Input';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import API from '../stores/API';
import {
  saveID,
  saveToken
} from 'Components/localstorage';
import Spinner from '../components/Spinner';
import If from 'Conditionals/If';

const LogIn = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [showRestMsg, setShowRestMsg] = useState(false);
  const [error, setError] = useState(null);
  const host = window && window.location && window.location.host;
  const apiOptions = {
    'stage.api.lavendla.com': 'https://stage.api.lavendla.com/v1',
    'stage-2.api.lavendla.com': 'https://stage-2.api.lavendla.com/v1',
    'api.lavendla.com': host.includes('localhost') ? 'https://api.lavendla.com/v1' : null,
    'api.lavendla.test': host.includes('localhost') ? 'https://api.lavendla.test/v1' : null
  };
  let defaultBackend = apiOptions['api.lavendla.com'];
  const urlParams = new URLSearchParams(window.location.search);

  if (host.includes('localhost')) {
    defaultBackend = apiOptions['api.lavendla.test'];
  } else if (host.includes('netlify.app')) {
    defaultBackend = apiOptions['stage.api.lavendla.com'];
  }

  const [backend, setBackend] = useState(localStorage.getItem('backend') || defaultBackend);

  useEffect(() => {
    localStorage.setItem('backend', backend);
  }, [backend]);

  const handleBackendChange = (e) => {
    setBackend(e.target.value);
    localStorage.setItem('backend', e.target.value);
    window.location.reload();
  };

  const handleSubmit = async () => {
    setError(null);
    setIsLoading(true);

    if (isResetPassword) {
      const res = await dispatch(sendResetEmail(email));

      if (res.status === 200) {
        setShowRestMsg(true);
      } else {
        setError('Could not find e-mail');
      }

      setIsLoading(false);
      return;
    }


    const response = await dispatch(login(email, password));
    setIsLoading(false);

    if (!response) {
      setError('Wrong username or password!');
    }
  };

  const isBtnDisabled = () => {
    if (isLoading) {
      return true;
    }

    if (isResetPassword) {
      return email === '';
    }

    return email === '' || password === '';
  };

  const googleLogin = useGoogleLogin({
    flow: 'implicit',
    scope: 'profile email openid https://www.googleapis.com/auth/calendar',
    onSuccess: async (codeResponse) => {
      setIsLoading(true);
      const response = await API.POST('/auth/google', {
        accessToken: codeResponse.access_token
      });

      saveID(response.user.id);
      saveToken(response.token);

      await dispatch(getLoggedInUser());
      setIsLoading(false);
    },
    onError: (errorResponse) => setError('Something went wrong. Try contacting helpdesk for more assistance.')
  });

  const motionProps = {
    initial: { opacity: 0, x: -25 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -25 }
  };

  const resetStates = () => {
    setEmail('');
    setPassword('');
    setIsLoading(false);
    setIsResetPassword(false);
    setShowRestMsg(false);
    setError(null);
  };

  return (
    <>
      <div className='login__wrapper'>
        <div className='logo'>
          <Link to='/' onClick={resetStates}>
            <ReactSVG src='/icons/lavendla-logo.svg' />
          </Link>
        </div>
        <div className='login__content'>
          <>
            {isLoading && <Spinner />}

            <If condition={!isLoading}>
              {(!isResetPassword && !urlParams.has('reset')) &&
                <>
                  <div className='google-login'>
                    <button onClick={(codeResponse) => googleLogin(codeResponse)}>
                      <ReactSVG src='/icons/google-logo.svg' />
                      <span>Sign in with your Lavendla Google Account</span>
                    </button>
                  </div>

                  {!error ? <div className="separator">Or sign in with email and password</div> : null}
                </>}

              {showRestMsg &&
                <p>An email with instructions on how to reset your password has been sent to { email }</p>}

              {!showRestMsg ? <>
              {error && (
                <AnimatePresence>
                  <motion.div {...motionProps} className='inline-notice warning'>
                    <p>{error}</p>
                  </motion.div>
                </AnimatePresence>
              )}

              {urlParams.has('reset') &&
                <p>The password has been reset</p>}

              <form onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
              >
                <Input
                  data-cy='login-email'
                  label='E-mail'
                  type='email'
                  id='email'
                  value={email}
                  onChange={value => setEmail(value)}
                  floatingLabel={true}
                />

                {!isResetPassword &&
                  <Input
                    data-cy='login-password'
                    label='Password'
                    type='password'
                    value={password}
                    onChange={value => setPassword(value)}
                    floatingLabel={true}
                  />}

                <button
                  disabled={isBtnDisabled()}
                  className='btn full-width'
                  type='submit'
                >
                  {isResetPassword ? 'Reset password' : 'Log in'}
                </button>

                {!isResetPassword && (
                  <p className='login__reset-link'><a href='#' onClick={e => setIsResetPassword(true)}>Forgot password?</a></p>
                )}
              </form>
              </> : null}
            </If>
          </>
        </div>

        <If condition={host.includes('netlify.app') || host.includes('localhost')}>
          <div className='backend-select'>
            <label htmlFor='backend'>Select API:</label>
            <select
              data-cy='login-backend-select'
              id='backend'
              value={backend}
              onChange={handleBackendChange}
            >
              {Object.keys(apiOptions).map((key) => {
                if (!apiOptions[key]) {
                  return null;
                }

                return <option key={key} value={apiOptions[key]}>{key}</option>;
              })}
            </select>
          </div>
        </If>
      </div>
    </>
  );
};

export default LogIn;
