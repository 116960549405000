import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const config = {
  toolbar: ['heading', 'bold', 'italic', 'bulletedList', 'numberedList', 'link']
};

const Editor = ({ data, onChange }) => {
  const debouncedOnChange = useCallback(
    debounce((editor) => {
      onChange(editor.getData());
    }, 500),
    [onChange]
  );

  React.useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      config={config}
      onChange={(event, editor) => {
        if (editor.ui.focusTracker.isFocused && event.name === 'change:data') {
          debouncedOnChange(editor);
        }
      }}
    />
  );
};

Editor.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func
};

export default Editor;
