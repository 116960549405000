import React, { useState, useEffect, useRef } from 'react';
import NoticeList from './NoticeList';
import ReactSVG from 'react-svg';
import moment from 'moment';
import { getID } from '../localstorage';
import API from '../../stores/API';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const NoticeWidget = () => {
  const { t } = useTranslation();
  const { loggedInUser } = useLoggedInUser();
  const node = useRef();
  const [isVisible, updateIsVisible] = useState(false);
  const [notices, updateNotices] = useState([]);
  const [hasBeenOpened, updateHasBeenOpened] = useState(false);
  const [loading, updateLoading] = useState(false);

  const className = classNames('notice-widget', { loading });

  const getNotices = () => {
    if (!loggedInUser) {
      return;
    }

    API.GET(`/notices/${getID()}?limit=20`)
      .then(response => {
        updateHasBeenOpened(false);

        if (response && response.status === 'ok') {
          updateNotices(response.data);
        }
      });
  };

  useEffect(() => {
    getNotices();
    setInterval(getNotices, 60000 * 5);

    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = e => {
    if (!node.current || !e || !e.target) {
      return;
    }

    if (!node.current.contains(e.target)) {
      toggleList();
    }
  };

  const toggleList = (open = false) => {
    if (!open) {
      markAllRead();
      updateIsVisible(false);
    } else {
      updateIsVisible(true);
    }
  };

  const markRead = async id => {
    const index = notices.findIndex(notice => notice.id === id);
    const noticeCopy = [...notices];

    noticeCopy[index].acknowledged_at = moment().format('YYYY-MM-DD HH:mm:ss');
    updateNotices(noticeCopy);

    await API.POST(`/notices/${id}/acknowledge`);
  };

  const markAllRead = async () => {
    const filterNotices = notices.filter(({ acknowledged_at: acknowledgedAt }) => !acknowledgedAt);

    if (filterNotices.length === 0) {
      return;
    }

    for (const { id } of filterNotices) {
      await markRead(id);
    }
  };

  const deleteNotice = async (id) => {
    const index = notices.findIndex(notice => notice.id === id);
    const noticesCopy = [...notices];
    noticesCopy.splice(index, 1);
    updateNotices(noticesCopy);

    await API.DELETE(`/notices/${id}`);
  };

  const deleteAllNotices = async () => {
    updateLoading(true);
    const userId = getID();

    await API.DELETE(`/notices/${userId}/delete-all`);

    updateNotices([]);
    updateLoading(false);
  };

  const getNumberUnread = () => {
    return notices.filter(({ acknowledged_at: acknowledgedAt }) => !acknowledgedAt).length;
  };

  const showNumberUnread = () => {
    if (getNumberUnread() === 0) {
      return false;
    }

    return !hasBeenOpened;
  };

  return (
    <div
      ref={node}
      className={className}
    >
      <div
        className='notice-widget__icon'
        onClick={() => {
          toggleList(!isVisible);
          updateHasBeenOpened(true);
        }}
      >
        <span className='notice-widget__icon__image small'>
          <ReactSVG
            src='/icons/icon-notice.svg'
            className='notice-widget__icon__image__svg small'
          />
          <div className='label'>
            {t('Notices')}
          </div>
        </span>
        <ReactSVG
          src='/icons/icon-notice-circle.svg'
          className='notice-widget__icon__image__svg large'
        />
        {showNumberUnread() ? (
          <div className='notice-widget__icon__count'>
            <span>
              {getNumberUnread()}
            </span>
          </div>
        ) : null}
      </div>
      {isVisible ? (
        <div className='notice-widget__list'>
          <NoticeList
            notices={notices}
            markRead={markRead}
            toggleList={toggleList}
            deleteNotice={deleteNotice}
            deleteAllNotices={deleteAllNotices}
          />
        </div>
      ) : null}
    </div>
  );
};

export default NoticeWidget;
