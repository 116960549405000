import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'Library/functions';
import API from '../../stores/API';
import store from '../../stores/RootStore';
import LoadingButton from 'FormElements/LoadingButton';

function InvoiceExportInformation ({ invoice, countryCode, dateFormat }) {
  const [isExporting, setIsExporting] = useState(false);
  const [invoiceExport, setInvoiceExport] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setInvoiceExport(invoice.visma_invoice_export);
  }, []);

  const invoiceExportQueuedAt = invoiceExport?.additional_data?.latest_queued_at || '';
  const invoiceExportStartedAt = invoiceExport?.started_at || '';
  const invoiceExportCompletedAt = invoiceExport?.completed_at || '';
  const invoiceExportFailedAt = invoiceExport?.failed_at || '';
  const invoiceExportErrors = invoice.invoice_export_errors || [];

  const contextLabels = {
    create: t('Created'),
    status_check: t('Status check'),
  };

  const retryExport = () => {
    setIsExporting(true);

    API.POST(`/invoices/${invoice.id}/visma-export/retry`).then((result) => {
      if (result.status === 'ok') {
        store.noticeStore.showNotice(t('The invoice will soon be exported to Visma'));

        setInvoiceExport(result.invoiceExport);

      } else {
        store.noticeStore.showWarningNotice(t('Something went wrong'));
      }

      setTimeout(() => {
        setIsExporting(false);
      }, 500);
    });
  };

  return (
    <div className='invoice-export-information'>
      <If condition={!!invoiceExport}>
        <div>
          <strong>
            {t('Visma export queued for export')}
          </strong>{' '}
          {invoiceExportQueuedAt ? formatDate(invoiceExportQueuedAt, countryCode, dateFormat) : '-'}
        </div>
        <div>
          <strong>
            {t('Visma export started')}
          </strong>{' '}
          {invoiceExportStartedAt ? formatDate(invoiceExportStartedAt, countryCode, dateFormat) : '-'}
        </div>

        <div>
          <strong>
            {t('Visma export completed')}
          </strong>{' '}
          {invoiceExportCompletedAt ? formatDate(invoiceExportCompletedAt, countryCode, dateFormat) : '-'}
        </div>

        <If condition={!!invoiceExportFailedAt}>
          <div>
            <strong>
              {t('Visma export failed')}
            </strong>{' '}
            {invoiceExportFailedAt ? formatDate(invoiceExportFailedAt, countryCode, dateFormat) : '-'}
          </div>
        </If>

        <If condition={!!invoiceExportCompletedAt && invoiceExportErrors.length > 0}>
          <div className="invoice-export-information__errors">
            <strong>
              {t('Visma error messages')}
            </strong>
            {invoiceExportErrors.map((error, index) => (
              <div
                key={`invoice_error_${index}`}
                className="invoice-export-information__errors__single-item"
              >
                <div>
                  <span className="invoice-export-information__errors__single-item__label">{t('Context')}:</span>
                  <span>{contextLabels[error.context] || '-'}</span>
                </div>
                <div>
                  <span className="invoice-export-information__errors__single-item__label">{t('Message')}:</span>
                  <span>{error.message || '-'}</span>
                </div>
                <div>
                  <span className="invoice-export-information__errors__single-item__label">{t('Created at')}:</span>
                  <span>{error.created_at || '-'}</span>
                </div>
              </div>
            ))}
          </div>
        </If>

        <If condition={!invoiceExportCompletedAt && !!invoiceExportFailedAt}>
          <LoadingButton
            className='btn small invoice-export-information__retry-button'
            type='button'
            onClick={retryExport}
            isLoading={isExporting}
          >
            {t('Export again')}
          </LoadingButton>
        </If>
      </If>
    </div>
  );
}

InvoiceExportInformation.propTypes = {
  invoice: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default InvoiceExportInformation;
