import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'Components/Modal';
import { If } from 'Conditionals/index';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import RequiresModerationModalContent from './RequiresModerationModalContent';
import ReplyForm from './ReplyForm';
import SingleReviewStars from './SingleReviewStars';
import SelectUserModalContent from './SelectUserModalContent';

function SingleReview ({ review, updateReply, refreshReviews, updateRequiresModeration, updateUser, users }) {
  const [currentReview, setCurrentReview] = useState(review);
  const [requiresModalIsOpen, setRequiresModerationModalIsOpen] = useState(false);
  const [selectUserModalIsOpen, setSelectUserModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const openRequiresModerationModal = () => {
    setRequiresModerationModalIsOpen(true);
  };

  const closeRequiresModerationModal = () => {
    setRequiresModerationModalIsOpen(false);
  };

  const openSelectUserModal = () => {
    setSelectUserModalIsOpen(true);
  };

  const closeSelectUserModal = () => {
    setSelectUserModalIsOpen(false);
  };

  const dispatchReply = async (review) => {
    setIsLoading(true);

    setCurrentReview(review);

    await updateReply(review);

    setIsLoading(false);
  };

  const dispatchRequiresModeration = async (review) => {
    setIsLoading(true);

    setCurrentReview(review);

    await updateRequiresModeration(review);

    closeRequiresModerationModal();

    setIsLoading(false);
  };

  const dispatchUpdateUser = async (review) => {
    setIsLoading(true);

    setCurrentReview(review);

    const { status, data } = await updateUser(review);

    if (status === 'ok') {
      setCurrentReview(data);
    }

    closeSelectUserModal();

    setIsLoading(false);

    refreshReviews();
  };

  return (
    <div className={'single-review'}>
      <div className={'single-review__container'}>
        <div className={'single-review__container__header'}>
          <SingleReviewStars review={currentReview} />
          <If condition={currentReview.source === 'trustpilot'}>
            <button
              type="button"
              className={'single-review__container__header__requires-moderation-button'}
              style={{
                filter: currentReview.requires_moderation ? 'grayscale(0)' : 'grayscale(1)',
              }}
              onClick={openRequiresModerationModal}
            >
              <ReactSVG src="/icons/flag.svg" />
            </button>
          </If>
        </div>

        <div className={'single-review__container__content'}>
          <span className={'single-review__container__content__title'}>{currentReview.title}</span>
          <p>
            {currentReview.review}
          </p>
        </div>

        <If condition={currentReview.source === 'trustpilot'}>
          <ReplyForm
            review={currentReview}
            isLoading={isLoading}
            updateReply={dispatchReply}
          />
        </If>
      </div>

      <div className={'single-review__aside'}>
        <ul>
          <li>
            <span>{t('User')}</span>
            <span>
              <If condition={!!currentReview.user?.name}>
                {currentReview.user?.name}
              </If>
              <If condition={!currentReview.user_id}>
                <button
                  onClick={openSelectUserModal}
                  className="btn btn-link"
                >
                   {t('Select user')}
                 </button>
              </If>
            </span>
          </li>
          <li>
            <span>{t('Customer')}</span>
            <span>{currentReview.customer_name}</span>
          </li>
          <li>
            <span>{t('Reference')}</span>
            <span>{currentReview.order?.number || '-'}</span>
          </li>
          <li>
            <span>{t('Date')}</span>
            <span>{currentReview.review_date}</span>
          </li>
          <li>
            <span>{t('Source')}</span>
            <span>{currentReview.source_label}</span>
          </li>
          <li>
            <span>{t('Market')}</span>
            <span>{currentReview.market_label}</span>
          </li>
          <li>
            <span>{t('Language')}</span>
            <span>{currentReview.language_label}</span>
          </li>
        </ul>
      </div>

      <Modal
        close={closeRequiresModerationModal}
        className="single-review__modal"
        headerTitle={t('Requires moderation')}
        isOpen={requiresModalIsOpen}
      >
        <If condition={requiresModalIsOpen}>
          <RequiresModerationModalContent
            review={currentReview}
            isLoading={isLoading}
            updateRequiresModeration={dispatchRequiresModeration}
            cancel={closeRequiresModerationModal}
          />
        </If>
      </Modal>

      <Modal
        close={closeSelectUserModal}
        className="single-review__modal select-user-modal"
        headerTitle={t('Select user')}
        isOpen={selectUserModalIsOpen}
      >
        <If condition={selectUserModalIsOpen}>
          <SelectUserModalContent
            review={currentReview}
            isLoading={isLoading}
            users={users}
            updateUser={dispatchUpdateUser}
            cancel={closeSelectUserModal}
          />
        </If>
      </Modal>
    </div>
  );
}

SingleReview.propTypes = {
  review: PropTypes.object,
  isLoading: PropTypes.bool,
  refreshReviews: PropTypes.func,
  updateRequiresModeration: PropTypes.func,
  updateUser: PropTypes.func,
  users: PropTypes.array,
};

export default SingleReview;
