import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CompetenceTab from 'Components/User/CompetenceTab';
import { If } from 'Conditionals';
import { LoadingButton } from 'FormElements';
import useTabIndexes from 'Hooks/useTabIndexes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { ROLES } from 'ReduxActions/users';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import useUser from 'ReduxHooks/useUser';
import { getMissingRequirements } from '../../utils/userProfileUtils';
import Spinner from '../Spinner';
import UserMeetingTimes from '../UserMeetingTimes/UserMeetingTimes';
import AdminForm from './AdminForm';
import './Profile.scss';
import ReviewsTab from './ReviewsTab';
import UserEconomyTab from './UserEconomyTab';
import UserForm from './UserForm';
import UserNotificationSettingsTab from './UserNotificationSettingsTab';
import UserProfileImage from './UserProfileImage';
import UserProfileMarkets from './UserProfileMarkets';

const Profile = ({ match }) => {
  const { t } = useTranslation();
  const { user, persist } = useUser();
  const { loggedInUser, isAdmin, hasOnlyLegalAdvisorRole } = useLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab] = useState(0);
  const isLoggedInUser = user.id === loggedInUser.id;
  const isPaused = !!user.paused_at;
  const showNoticesTab = user.roles.includes(ROLES.FUNERAL) || isAdmin;
  const showCompetenceTab = !hasOnlyLegalAdvisorRole();
  const showAdminTab = isAdmin;

  // Get profile status
  const getProfileMissingRequirements = () => {
    return getMissingRequirements(user, null, user.roles, t);
  };

  const userCanActivateProfile = () => getProfileMissingRequirements().length === 0;
  const isProfileActive = user.user_profiles?.some(profile => profile.active);

  const { tabIndex, updateTabs } = useTabIndexes({
    uri: match.url.includes('/anvandare') ? `/anvandare/id/${match.params.id}` : '/profil',
    tab: match.params.tab || null,
    type: 'Profile',
    authorizationRules: {
      showNoticesTab,
      showCompetenceTab,
      showAdminTab
    }
  });

  if (!user) {
    return (
      <Spinner />
    );
  }

  async function persistUser () {
    setIsLoading(true);
    await persist();
    setIsLoading(false);
  }

  return (
    <div className='row order-tab__form'>
      <form className='col-12 user-profile'>
        <div className='page-header mb-4'>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h2 className='page-title mb-2'>
                {isLoggedInUser ? t('My profile') : t('Edit user')}
              </h2>
              <div className="status-text d-flex align-items-center">
                <If condition={!!user.archived_at}>
                  <em className="me-3">({t('Inactive')})</em>
                </If>
                <If condition={!isLoggedInUser && isPaused}>
                  <em>({t('This user is paused')})</em>
                </If>
              </div>
            </div>
            {!isProfileActive && (
              <button
                type="button"
                className={classNames('btn publish-button', {
                  'white': isProfileActive,
                  'light': !isProfileActive,
                  'inactive': !userCanActivateProfile()
                })}
                onClick={() => updateTabs(3)}
                disabled={isPaused}
              >
                <FontAwesomeIcon
                  className="button-icon"
                  icon={['fal', isProfileActive
                    ? 'check-circle'
                    : !userCanActivateProfile()
                      ? 'exclamation-triangle'
                      : 'globe'
                  ]}
                />
                {userCanActivateProfile()
                  ? t('Ready to publish profile')
                  : t('Cannot publish profile')}
              </button>
            )}
          </div>
        </div>
        <Tabs selectedIndex={tabIndex} onSelect={index => updateTabs(index)}>
          <div className='row'>
            <div className='col-12'>
              <TabList className='react-tabs__tab-list react-tabs__has-sub-tabs tab-container'>
                <Tab
                  className='tab'
                  data-tab='my-profile'
                  selectedClassName="tab--selected"
                >
                  {isLoggedInUser ? t('My profile') : t('User')}
                </Tab>
                <Tab
                  className={classNames('tab', { hidden: hasOnlyLegalAdvisorRole() })}
                  data-tab='competences'
                  selectedClassName="tab--selected"
                >
                  {t('Competences')}
                </Tab>
                <Tab
                  className='tab'
                  data-tab='schedule'
                  selectedClassName="tab--selected"
                >
                  {t('Schedule & meeting times')}
                </Tab>
                {user.countries.length ? (
                  <Tab
                    className='tab'
                    data-tab='profile'
                    selectedClassName="tab--selected"
                  >
                    {t('Profile')}
                  </Tab>
                ) : null}
                {showAdminTab ? (
                  <Tab
                    className='tab'
                    data-tab='admin'
                    selectedClassName="tab--selected"
                  >
                    {t('Admin')}
                  </Tab>
                ) : null}
                {showNoticesTab ? (
                  <Tab
                    className='tab'
                    data-tab='notifications'
                    selectedClassName="tab--selected"
                  >
                    {t('Notifications')}
                  </Tab>
                ) : null}
                <Tab
                  className='tab'
                  data-tab='economy'
                  selectedClassName="tab--selected"
                >
                  {t('Economy')}
                </Tab>
                <Tab
                  className='tab'
                  data-tab='reviews'
                  selectedClassName="tab--selected"
                >
                  {t('Reviews')}
                </Tab>
              </TabList>
            </div>
          </div>
          <style>
            {`
              .react-tabs__tab--selected {
                color: #1a1a1a !important;
                background: white !important;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
              }
              .react-tabs__tab:hover:not(.react-tabs__tab--selected) {
                color: #1a1a1a;
                background: rgba(255, 255, 255, 0.5);
              }
              .react-tabs__tab.hidden {
                display: none;
              }
            `}
          </style>
          <TabPanel>
            <div className='row new-form'>
              <div className='col-8'>
                <UserForm isCreateMode={false} />
              </div>
              <div className='col-4'>
                <UserProfileImage />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <CompetenceTab />
          </TabPanel>
          <TabPanel>
            <UserMeetingTimes />
          </TabPanel>
          {user.countries.length ? (
            <TabPanel>
              <UserProfileMarkets />
            </TabPanel>
          ) : null}
          {showAdminTab ? (
            <TabPanel>
              <div className='row new-form'>
                <div className='col-8'>
                  <AdminForm />
                </div>
              </div>
            </TabPanel>
          ) : null}
          {showNoticesTab ? (
            <TabPanel>
              <UserNotificationSettingsTab />
            </TabPanel>
          ) : null}
          <TabPanel>
            <div className='row new-form'>
              <div className='col-8'>
                <UserEconomyTab />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <ReviewsTab />
          </TabPanel>
        </Tabs>

        <div className='btn-group'>
          {activeTab === 3 && user?.user_profiles.length === 0 ? null : (
            <LoadingButton
              onClick={() => persistUser()}
              text={t('Save')}
              data-cy='persist-user'
              isLoading={isLoading}
            />
          )}
        </div>
      </form>
    </div>
  );
};

Profile.propTypes = {
  match: PropTypes.object.isRequired
};

export default Profile;
