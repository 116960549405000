import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import UserBar from './UserBar';
import SearchActionIcon from './SearchActionIcon';
import MenuActionIcon from './MenuActionIcon';
import NoticeWidget from './Notices/NoticeWidget';
import HeaderSearch from './HeaderSearch';
import classNames from 'classnames';
import useSettings from 'ReduxHooks/useSettings';

function TopBar () {
  const [scrollY, setScrollY] = useState();
  const { getSetting, setSetting } = useSettings();

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize, 500));
    window.addEventListener('scroll', throttle(handleScroll, 100));

    return () => {
      window.removeEventListener('resize', debounce(handleResize, 500));
      window.removeEventListener('scroll', throttle(handleScroll, 100));
    };
  });

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      document.body.classList.remove('topbar-hidden');
    }
  };

  const handleScroll = () => {
    if (window.innerWidth >= 768) {
      return;
    }

    const previous = scrollY;
    const current = window.scrollY;
    const delta = current - previous;

    if (delta < 0) {
      // scrolled up
      document.body.classList.remove('topbar-hidden');
    } else if (delta > 0) {
      // scrolled down
      if (scrollY >= 60) {
        document.body.classList.add('topbar-hidden');
        if (getSetting('showSearchbar')) {
          setSetting({ showSearchbar: false });
        }
      }
    }

    setScrollY(current);
  };

  function showDevNotice () {
    return window.location.host.includes('netlify') || window.location.host.includes('localhost');
  }

  let apiUrlParam = API_URL;
  if (window.location.host.includes('netlify.app') || window.location.host.includes('localhost')) {
    apiUrlParam = localStorage.getItem('backend') || 'https://stage.api.lavendla.com/v1';
  }

  return (
    <div className={classNames('topbar', { dev: showDevNotice() })}>
      <div className='dev-notice'>
        <p>Development environment · API: {apiUrlParam}</p>
      </div>

      <div className='logo'>
        <Link to='/'>
          <ReactSVG src='/icons/lavendla-logo.svg' />
        </Link>
      </div>
      <div className='topbar__menu-links'>
        <SearchActionIcon />
        <HeaderSearch />
        <MenuActionIcon />
        <NoticeWidget />
        <UserBar />
      </div>
    </div>
  );
}

export default TopBar;
