import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import General from './Tabs/General';
import Email from './Tabs/Email';
import File from './Tabs/File';
import Reminders from './Tabs/Reminders';
import useTask from 'ReduxHooks/useTask';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { LoadingButton } from 'FormElements';
import { useHistory } from 'react-router-dom';
import { AdminOnly, If } from 'Conditionals';
import { useTranslation } from 'react-i18next';

function TaskCreator ({
  task = {
    id: null,
    title: '',
    description: '',
    hide_confirmation_button: false,
    hide_completed_button: false,
    send_trustpilot_email: false,
    send_google_review_email: false,
    send_lavendla_review_email: false,
    inmemory: false,
    is_offer_task: false,
    is_updated_offer_task: false,
    is_invoice_task: false,
    allowlist: false,
    can_send_email_to_customer_contacts: false,
    type: 'order',
    reminder: null,
    email_to: '',
    email_subject: '',
    emailbody: '',
    minutes_to_log: 0,
    priority: 0,
    files: [],
    competence_areas: [],
    country: null
  }
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { update, create, destroy, detachFile, taskCountry, setTaskCountry } = useTask();
  const { loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [updatedTask, setUpdatedTask] = useState(task);
  const [isLoading, setIsLoading] = useState(false);
  const file = useRef();

  useEffect(() => {
    setTaskCountry(task.country || userCountry);
  }, []);

  const updateValue = (args = {}) => {
    setUpdatedTask({
      ...updatedTask,
      ...args
    });
  };

  const removeFile = async (id) => {
    await detachFile(id);

    updateValue({ files: [] });
  };

  const submit = async () => {
    const formData = new FormData();

    Object.keys(updatedTask).forEach(key => {
      let value;

      switch (key) {
        case 'hide_confirmation_button':
        case 'hide_completed_button':
        case 'hide_email_button':
        case 'send_trustpilot_email':
        case 'send_google_review_email':
        case 'send_lavendla_review_email':
        case 'inmemory':
        case 'is_offer_task':
        case 'is_updated_offer_task':
        case 'is_invoice_task':
        case 'is_payment_task':
        case 'optional':
        case 'allowlist':
        case 'can_send_email_to_customer_contacts':
          value = updatedTask[key] ? '1' : '0';
          break;

        case 'reminder':
        case 'countries':
        case 'competence_areas':
          value = JSON.stringify(updatedTask[key]);
          break;

        default:
          value = updatedTask[key];
          break;
      }

      formData.append(key, value);
    });

    if (file.current && file.current.files && file.current.files.length) {
      formData.append('attached_file', file.current.files[0]);
    }

    if (taskCountry.id) {
      formData.append('country_id', taskCountry.id);
    }

    setIsLoading(true);

    if (task.id) {
      await update(task.id, formData);
    } else {
      const task = await create(formData);

      history.push(`/tasks/task/${task.id}`);
      return;
    }

    setIsLoading(false);
  };

  const deleteTask = async () => {
    if (!window.confirm(t('Are you sure?'))) {
      return;
    }

    setIsLoading(true);
    await destroy(task.id);

    history.push('/tasks');
  };

  return (
    <form className='order-tab__form new-form'>
      <Tabs>
        <TabList>
          <Tab data-cy='task-tab-general'>{t('Task', { description: 'Uppgiften' })}</Tab>
          <Tab data-cy='task-tab-email'>{t('Email content', { description: 'Mailinnehåll' })}</Tab>
          <Tab data-cy='task-tab-files'>{t('Files')}</Tab>
          <Tab data-cy='task-tab-reminders'>{t('Reminders')}</Tab>
        </TabList>
        <TabPanel>
          <General
            updatedTask={updatedTask}
            updateValue={updateValue}
          />
        </TabPanel>
        <TabPanel>
          <Email
            updatedTask={updatedTask}
            updateValue={updateValue}
          />
        </TabPanel>
        <TabPanel>
          <File
            updatedTask={updatedTask}
            removeFile={removeFile}
            refProp={file}
          />
        </TabPanel>
        <TabPanel>
          <Reminders
            updatedTask={updatedTask}
            updateValue={updateValue}
          />
        </TabPanel>
      </Tabs>

      <AdminOnly>
        <LoadingButton
          onClick={submit}
          isLoading={isLoading}
          data-cy='btn_save'
          text={task.id ? t('Save') : t('Create')}
        />
        <If condition={task.id}>
          &nbsp;&nbsp;
          <LoadingButton
            data-cy='btn_delete'
            disabled={isLoading}
            onClick={deleteTask}
            className='delete'
          >
            {t('Delete')}
          </LoadingButton>
        </If>
      </AdminOnly>
    </form>
  );
}

TaskCreator.propTypes = {
  task: PropTypes.object
};

export default TaskCreator;
