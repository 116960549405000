import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ReactSVG from 'react-svg';
import store from '../stores/RootStore';
import API from '../stores/API';
import { useTranslation } from 'react-i18next';

const defaultFields = [
  'id',
  'name',
  'email',
  'phone',
  'mobile_phone',
  'company_name',
  'physical_meetings',
  'country_market',
  'addresses',
  'competence_areas',
  'roles',
  'locations',
  'schedule_active',
  'published_profiles',
  'google_calendar_sync_enabled',
  'stripe_enabled',
  'stripe_account',
  'stripe_charges_enabled',
  'stripe_payouts_enabled',
  'stripe_verified',
  'stripe_requirements',
  'prices',
  'has_fixed_fee'
];

const UsersExport = ({ search, type, location }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fields, setFields] = useState(defaultFields);

  const onFieldsChange = e => {
    const newFields = [...fields];

    if (e.target.checked) {
      newFields.push(e.target.id);
    } else {
      newFields.splice(fields.indexOf(e.target.id), 1);
    }

    setFields(newFields);
  };

  const submit = e => {
    const filter = {
      type: 'users',
      search,
      supplier_type: type,
      location,
      fields
    };

    API.POST('/export/users', filter).then(res => {
      if (res.status === 'ok') {
        store.noticeStore.showNotice(res.data.response);
      } else {
        store.noticeStore.showWarningNotice('Det uppstod ett fel');
      }
    });
  };

  return (
    <div>
      <button className='btn' onClick={e => setIsModalOpen(true)}>{t('Export users')}</button>

      <Modal className='order-product-modal' isOpen={isModalOpen} onRequestClose={e => setIsModalOpen(false)} contentLabel=''>
        <button className='close-modal' onClick={e => setIsModalOpen(false)}>
          <ReactSVG src='/icons/icon-close-black.svg' />
        </button>

        {defaultFields.map((item, i) => (
          <div key={i}>
            <label>
              <input
                type='checkbox'
                checked={fields.includes(item)}
                onChange={onFieldsChange}
                id={item}
              />
              {item}
            </label>
          </div>
        ))}
        <br />
        <button
          className='btn' onClick={e => {
          setIsModalOpen(false);
          submit(e);
        }}
        >{t('Export users')}
        </button>
      </Modal>
    </div>
  );
};

UsersExport.propTypes = {
  search: PropTypes.string,
  type: PropTypes.string,
  location: PropTypes.string
};

export default UsersExport;
