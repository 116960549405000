import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSVG from 'react-svg';
import Spinner from '../Spinner';
import TaskConfirmedAt from './TaskConfirmedAt';
import TaskConfirmOffer from './TaskConfirmOffer';
import TaskInfoModal from './TaskInfoModal';
import Checkbox from './Checkbox';
import { LoadingButton } from 'FormElements';
import {
  isInvoiceTask,
  isOfferTask,
  isUpdatedOfferTask,
  isCreateTombstoneOrderTask,
  isCreateLawyerOrderTask,
  isPaymentTask,
  isLavendlaReviewtask,
} from 'Library/Tasks';
import { orderHasInvoicedAllInvoiceableProducts } from 'Library/ProductHelper';
import OrderTaskModals from './OrderTaskModals';
import useOrderOffers from 'ReduxHooks/useOrderOffers';
import useOrderTasks from 'ReduxHooks/useOrderTasks';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { If } from 'Conditionals';
import { Trans, useTranslation } from 'react-i18next';
import useTaskActions from 'Hooks/useTaskActions';

function OrderTask ({
  task,
  canSendTasks = true
}) {
  const { t } = useTranslation();
  const { confirmOffer, order, isLawyerOrder, isTherapyOrder } = useSingleOrder();
  const { confirm, getActiveOffer, hasConfirmedOffer } = useOrderOffers();
  const { markTaskIncomplete, markTaskCompleted } = useOrderTasks();
  const { sendEmail } = useTaskActions(task);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [verbalOfferModalIsOpen, setVerbalOfferModalIsOpen] = useState(false);
  const canSend = (task.allowlist) ? true : canSendTasks;

  const toggleStatus = async (isCompleted) => {
    setIsLoading(true);

    if (isCompleted) {
      await markTaskCompleted(task.id);
    } else {
      await markTaskIncomplete(task.id);
    }

    setIsLoading(false);
  };

  const sendInMemoryTask = async () => {
    setIsLoading(true);

    if (await sendEmail()) {
      await markTaskCompleted(task.id);
      setIsLoading(false);
    }
  };

  const confirmCallback = async () => {
    const activeOffer = getActiveOffer();
    setIsLoading(true);

    if (activeOffer) {
      await confirm(activeOffer.id);
    } else {
      await confirmOffer();
    }

    setIsLoading(false);
  };

  const sentVerbalOffer = async () => {
    setVerbalOfferModalIsOpen(false);
  };

  function showVerbalOfferButton () {
    return isLawyerOrder && isOfferTask(task);
  }

  function isSendButtonDisabled () {
    if (!canSend) {
      return true;
    }

    if (isOfferTask(task) && hasConfirmedOffer()) {
      if (isTherapyOrder) {
        return orderHasInvoicedAllInvoiceableProducts(order);
      }

      return true;
    }

    if (isOfferTask(task) && isTherapyOrder) {
      return orderHasInvoicedAllInvoiceableProducts(order);
    }

    if (isLavendlaReviewtask(task) && !hasConfirmedOffer()) {
      return true;
    }

    if (!isInvoiceTask(task)) {
      return false;
    }

    return orderHasInvoicedAllInvoiceableProducts(order);
  }

  function sendTaskText () {
    if (isPaymentTask(task)) {
      return (task.pivot.completed) ? t('Send new payment') : t('Send payment');
    }

    if (isOfferTask(task)) {
      if (isTherapyOrder) {
        return (task.pivot.completed) ? t('Send new payment') : t('Send payment');
      }

      return (task.pivot.completed) ? t('Send new offer') : t('Send offer');
    }

    if (isUpdatedOfferTask(task)) {
      return t('Send new');
    }

    if (isCreateTombstoneOrderTask(task)) {
      return t('Create tombstone order');
    }

    if (isCreateLawyerOrderTask(task)) {
      return t('Create lawyer order');
    }

    return (task.pivot.completed) ? t('Resend email') : t('Send email');
  }

  return (
    <div
      data-cy={`task-${task.id}`}
      className={classNames('task', {
        is_loading: isLoading,
        completed: !!task.pivot.completed,
        'prio--high': task.priority === 1
      })}
    >
      <div className='task__header'>
        <h5 className='task__header__header'>
          {task.name}
          <If condition={task.optional}>
            <span className='task__header__header__optional-label'> ({t('optional')})</span>
          </If>
        </h5>
        <ReactSVG src='/icons/icon-warning.svg' className='task__header__icon' />
      </div>

      <div className={canSend ? 'task__content' : 'disabled task__content'}>
        <If condition={isLoading}>
          <Spinner />
        </If>

        <Checkbox
          data-cy={`task-checkbox__${task.id}`}
          task={task}
          toggleStatus={toggleStatus}
          isLoading={isLoading}
          canSendTasks={canSendTasks}
          className='task__checkbox'
        />

        <TaskConfirmedAt
          task={task}
          order={order}
        />

        <If condition={!isTherapyOrder}>
          <TaskConfirmOffer
            task={task}
            order={order}
            confirmOrder={confirmCallback}
          />
        </If>

        <div className='task__content__actions'>
          <If condition={task.inmemory}>
            <LoadingButton
              text={task.pivot.email_sent_at ? t('Update case') : t('Create case')}
              className='small'
              disabled={isSendButtonDisabled()}
              onClick={() => sendInMemoryTask()}
            />

            <If condition={task.pivot.email_sent_at}>
              <LoadingButton
                text={t('Open in InMemory')}
                className='light small'
                onClick={() => window.open(BACKEND_URL + 'inmemory/redirect/' + order.id, '_blank')}
              />
            </If>
          </If>

          <If condition={!task.hide_email_button}>
            <LoadingButton
              className='small'
              data-cy={`task-send-button__${task.id}`}
              text={sendTaskText()}
              disabled={isSendButtonDisabled()}
              onClick={() => setIsOpen(true)}
            />
          </If>
          <If condition={showVerbalOfferButton()}>
            <LoadingButton
              text='Muntlig offert'
              className='light small verbal-offer'
              disabled={isSendButtonDisabled()}
              onClick={() => setVerbalOfferModalIsOpen(true)}
            />
          </If>
          <TaskInfoModal task={task} />
          <If condition={isOfferTask(task) && hasConfirmedOffer() && !isTherapyOrder}>
            <div className='task__content__actions__note'>
              <Trans
                i18nKey='updateOrderTaskText'
                description='Use the task <strong>updated order confirmation</strong> is the client has already confirmed an offer and you wish to send a new order confirmation.'
                defaults='Use the task <strong>updated order confirmation</strong> is the client has already confirmed an offer and you wish to send a new order confirmation.'
              >
                Use the task <strong>updated order confirmation</strong> is the client has already confirmed an offer and you wish to send a new order confirmation.
              </Trans>
            </div>
          </If>
        </div>
      </div>

      <OrderTaskModals
        task={task}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        verbalOfferModalIsOpen={verbalOfferModalIsOpen}
        closeVerbalOfferModal={() => setVerbalOfferModalIsOpen(false)}
        sentVerbalOffer={sentVerbalOffer}
      />
    </div>
  );
}

OrderTask.propTypes = {
  task: PropTypes.object.isRequired,
  canSendTasks: PropTypes.bool
};

export default OrderTask;
