import { Notice } from 'Layout';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import API from '../stores/API';
import store from '../stores/RootStore';
import { Input } from './FormElements';
import LoadingButton from './FormElements/LoadingButton';

function HasLawyerOrder ({ orderId }) {
  const { t } = useTranslation();
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasLawyerOrder, setHasLawyerOrder] = useState(null);

  const onControlBtnClick = async () => {
    setIsLoading(true);
    setHasLawyerOrder(null);

    const res = await API.POST('/customers/has-lawyer-order', { social_security_number: socialSecurityNumber, order_id: orderId });

    setIsLoading(false);

    if (res.status === 'ok') {
      setHasLawyerOrder(res.has_lawyer_order);
    } else {
      store.noticeStore.showNotice(res.errors.error_message, 'error');
    }
  };

  return (
    <div className='has-lawyer-order'>
      <h4>{t('Verification of any potential counterpart for conflict of interest situation.')}</h4>

      <form>
        <Input
          label={t('Social Security Number')}
          value={socialSecurityNumber}
          onChange={value => setSocialSecurityNumber(value)}
        />

        <LoadingButton
          role='button'
          className='btn'
          onClick={() => onControlBtnClick()}
          disabled={!socialSecurityNumber}
          isLoading={isLoading}
        >
          {t('Check')}
        </LoadingButton>
      </form>

      {(hasLawyerOrder !== null && !hasLawyerOrder) && (
        <Notice type='success'>
          <h4>{t('No suspicion of conflict of interest')}</h4>
          <p>{t("Your client's counterpart is not in Lavendla's database, and there is no suspicion of conflict of interest.")}</p>
        </Notice>
      )}

      {(hasLawyerOrder !== null && hasLawyerOrder) && (
        <Notice type='warning'>
          <h4>{t('Potential conflict of interest')}</h4>
          <p>
            <Trans>
                Your client's counterpart is in Lavendla's database, please <a href='https://support.lavendla.se/' target='_blank' rel='noopener noreferrer'>contact Lavendla Support</a> for an investigation of conflict of interest.
            </Trans>
          </p>
        </Notice>
      )}
    </div>
  );
}

HasLawyerOrder.propTypes = {
  orderId: PropTypes.number.isRequired
};

export default HasLawyerOrder;
