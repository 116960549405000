import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import UserAssignerModal from './Components/UserAssignerModal';
import { parseQueryString } from 'Library/functions';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';
import { AdminOnly } from 'Conditionals/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderUsers from 'Components/OrderUsers';

function UserAssigner (props) {
  /* global history */
  const { t } = useTranslation();
  const [isMounted, setIsMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { order } = useSingleOrder();

  useEffect(() => {
    if (!isMounted) {
      const params = parseQueryString(window.location.search);
      setIsOpen(!!params.assign && (order && !order.user));
      setIsMounted(true);

      const stateObj = { path: window.location.pathname };
      history.replaceState(stateObj, '', stateObj.path);
    }
  }, [order, isMounted]);

  return (
    <>
      <div className="user-assigner">
        <strong>{order.user ? order.user.name : t('Unassigned')}</strong>
        {' '}
        <AdminOnly>
          {' '}
          <button
            role="button"
            className="btn btn-link"
            onClick={() => setIsOpen(true)}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={['fal', 'pen']}
              />
            </span>
          </button>
        </AdminOnly>

        <OrderUsers/>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel=""
        className='user-assigner-modal'
      >
        <UserAssignerModal
          {...props}
          close={() => setIsOpen(false)}
          isOpen={isOpen}
        />
      </Modal>
    </>
  );
}

export default UserAssigner;
