import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { If } from 'Conditionals';
import { Input, Toggle, Select, Fieldset } from 'FormElements';
import { Notice } from 'Layout';
import { getRoleLabel } from 'Library/UserRoles';
import useUser from 'ReduxHooks/useUser';
import CountryDropdown from 'Components/CountryDropdown';
import LavendlaDatePicker from 'Components/LavendlaDatePicker';
import AutoCompleteAddressInput from '../AutoCompleteAddressInput';
import { AnimatePresence, motion } from 'framer-motion';
import { animateTop } from 'Library/motion';
import SiteDropdown from 'Components/SiteDropdown';
import FeatureFlagsFormElements from 'Components/User/FeatureFlagsFormElements';
import RolesDropdown from 'Components/RolesDropdown';
import TherapyUserFormElements from 'Components/User/TherapyUserFormElements';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function UserForm ({ isCreateMode }) {
  const { t } = useTranslation();
  const { user, updateField } = useUser();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showMobilePhoneWarning, setShowMobilePhoneWarning] = useState(false);
  const [showPasswordWarning, setShowPasswordWarning] = useState(false);
  const { isAdmin } = useLoggedInUser();

  useEffect(() => {
    setShowPasswordWarning(false);

    if (!password || !password2) {
      return;
    }

    if (password === password2) {
      updateField('password', password);
      return;
    }

    setShowPasswordWarning(true);
  }, [password, password2]);

  const onAddressSelect = fullAddress => {
    updateField('address', fullAddress);
  };

  const getDefaultAddress = () => {
    if (!user.address || !user.address.city) {
      return '';
    }

    const { address_1: addressOne, city, country } = user.address;

    return [addressOne, city, country]
      .map(value => (value || '').trim())
      .filter(value => value)
      .join(', ');
  };

  const updateMobilePhone = (value) => {
    const regEx = /^[0-9\s+]+$/;

    setShowMobilePhoneWarning(false);

    if (value && !regEx.test(value)) {
      setShowMobilePhoneWarning(true);
    }

    updateField('mobile_phone', value);
  };

  const updateCountry = (countries) => {
    const country = (countries.length > 0) ? countries[0] : null;

    updateField('country', country);
  };

  const getRolesOptions = () => {
    const roles = user ? user.roles : [];

    return roles.reduce((acc, role) => ({ ...acc, [role]: t(getRoleLabel(role)) }), {});
  };

  return (
    <>
      <Input
        id='name'
        label={t('Name') + ' *'}
        floatingLabel
        value={user.name}
        onChange={value => updateField('name', value)}
        required
        showRequiredIcon
        autoComplete="off"
      />

      <If condition={isCreateMode}>
        <Input
          id='email'
          type='email'
          label={t('E-mail') + ' *'}
          floatingLabel
          value={user.email}
          onChange={value => updateField('email', value)}
          required
          showRequiredIcon
          autoComplete="off"
        />
      </If>

      <Input
        type='tel'
        floatingLabel
        id='mobile_phone'
        label={t('Mobile phone (NOTE! Enter numbers only)' + ' *')}
        value={user.mobile_phone}
        onChange={updateMobilePhone}
        required
        showRequiredIcon
        autoComplete="off"
      />

      <Input
        id='phone'
        type='tel'
        label={t('Phone (enter mobile phone number here if there is no separate phone number)') + ' *'}
        value={user.tel}
        floatingLabel
        onChange={value => updateField('tel', value)}
        required
        showRequiredIcon
        autoComplete="off"
      />

      <If condition={showMobilePhoneWarning}>
        <Notice type='error'>
          <strong>{t('NOTE Invalid mobile phone number!')}</strong><br /><br />{t('We will not be able to send out SMS notifications to this number.')}<br /><br />{t('Only numbers and spaces are allowed, eg 0705 123 456.')}
        </Notice>
        <br /><br />
      </If>

      <div
        data-cy='professionally_active_since'
        className={classNames('floating-label datepicker__floating-label input', {
          'has-content': user.professionally_active_since
        })}
      >
        <LavendlaDatePicker
          date={user.professionally_active_since}
          onChange={date => {
            date = date || null;
            updateField('professionally_active_since', date);
          }}
          showYearDropdown
          required
          autoComplete="off"
        />
        <label htmlFor='professionally_active_since'>{t('Professionally active since')} *</label>
      </div>

      <AutoCompleteAddressInput
        address={getDefaultAddress()}
        onAdressSelect={onAddressSelect}
        required
        autoComplete="off"
      />

      <If condition={isCreateMode}>
        <div className='react-select__floating-label floating-label input has-content'>
          <CountryDropdown
            id='country-dropdown'
            selected={user.country}
            onChange={updateCountry}
            required
            showRequiredIcon
            autoComplete="off"
          />
          <label htmlFor='country-dropdown'>{t('The home country of the user')}</label>
        </div>

        <If condition={!!user.country}>
          <div className='react-select__floating-label floating-label input has-content'>
            <SiteDropdown
              id='site-dropdown'
              selected={user.site_id}
              country={user.country}
              onChange={site => updateField('site_id', site.value)}
              required
              showRequiredIcon
              autoComplete="off"
            />
            <label htmlFor='site-dropdown'>{t('The home site of the user')}</label>
          </div>
        </If>

        <If condition={!user.country}>
          <div className='react-select__floating-label floating-label input has-content'>
            <Select
              options={[]}
              id='placeholder-site-dropdown'
              isDisabled
              label='The home site of the user'
              floatingLabel
              className='lavendla-select'
              onChange={() => {
              }}
              disabledPlaceholder={t('Select the home country before selecting the home site')}
              required
              showRequiredIcon
              autoComplete="off"
            />
          </div>
        </If>

        <div className='react-select__floating-label floating-label input has-content'>
          <CountryDropdown
            isMulti
            isClearable
            id='countries-dropdown'
            selected={user.countries}
            onChange={countries => updateField('countries', countries)}
            required
            showRequiredIcon
            autoComplete="off"
          />
          <label htmlFor='countries-dropdown'>{t('Select active markets for the user')}</label>
        </div>

        <div className='react-select__floating-label floating-label input has-content'>
          <RolesDropdown
            user={user}
            onChange={values => updateField('roles', values)}
            required
            showRequiredIcon
            autoComplete="off"
          />
          <label htmlFor='role-select'>{t('Roles')}</label>
        </div>
      </If>

      <If condition={isCreateMode}>
        <Select
          defaultOption={t('-- Select a default role --')}
          label={t('Default role')}
          value={user.default_role}
          options={getRolesOptions()}
          onChange={value => updateField('default_role', value)}
          className='lavendla-select location-dropdown'
          floatingLabel
          required
          showRequiredIcon
          isDisabled={user.roles.length === 0}
          disabledPlaceholder={t('Select roles before selecting the default role')}
          autoComplete="off"
        />

        <Toggle
          label={t('Accept remote orders')}
          defaultChecked={!!user.remote_user}
          onChange={() => updateField('remote_user', !user.remote_user)}
          data-cy='remote-user-toggle'
          required
          autoComplete="off"
        />

        <Fieldset legend={t('User permissions')} className='user-admin__fieldset'>
          <Toggle
            label={t('Allow phone hours in schedule')}
            defaultChecked
            onChange={() => updateField('allow_phone_hours', !user.allow_phone_hours)}
            data-cy='allow-phone-hours-toggle'
            required
            autoComplete="off"
          />
          <Toggle
            label={t('Self invoice')}
            defaultChecked
            onChange={() => updateField('self_invoice', !user.self_invoice)}
            data-cy='self-invoice-toggle'
            required
            autoComplete="off"
          />
        </Fieldset>
      </If>

      <If condition={isCreateMode}>
        <FeatureFlagsFormElements />
        <TherapyUserFormElements isCreateMode />
      </If>

      <If condition={isAdmin || (user.email && !user.email.includes('@lavendla'))}>
        <div className='grey-box password'>
          <Fieldset legend={user.id ? t('Change password') : t('Enter password')}>
            <Input
              floatingLabel
              label={user.id ? t('New password') : t('Password')}
              id='password1'
              type='password'
              value={password}
              onChange={value => setPassword(value)}
              autoComplete="new-password"
            />

            <Input
              floatingLabel
              label={t('Repeat password')}
              id='password2'
              type='password'
              value={password2}
              onChange={value => setPassword2(value)}
              autoComplete="new-password"
            />

            <AnimatePresence>
              <If condition={showPasswordWarning}>
                <motion.div {...animateTop}>
                  <Notice type='error'>
                    {t('The passwords do not match!')}
                  </Notice>
                  <br />
                </motion.div>
              </If>
            </AnimatePresence>
          </Fieldset>
        </div>
      </If>
    </>
  );
}

export default UserForm;
